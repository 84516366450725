.record-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: 250px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #3498db;
    color: #fff;
  }
  
  tr:hover {
    background-color: #f0f0f0;
  }
  